import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import Colors from 'colors.json'
import { Form, Formik } from 'formik'

import Input from 'components/Input'
import Button from 'components/Button'
import { UpdateMerchantIdentificationStatusesSchemas } from 'schemas/updateMerchantIdentificationStatusesSchemas'
import {
  MerchantDeviceTypes,
  MerchantIdentificationStatusesTypes,
  MerchantIdentificationTypes,
} from 'types/merchant'

import { useUpdateIdentification } from './useUpdateIdentification'
import Dropdown from 'components/Dropdown'
import { DropDownItem } from 'types/components'

interface PropsTypes {
  onCloseModal: () => void
  selectedMerchantIdentification: MerchantIdentificationTypes
}

const Index: React.FC<PropsTypes> = ({
  onCloseModal,
  selectedMerchantIdentification,
}): React.ReactElement => {
  // STATES
  // const merchantDeviceId = selectedMerchantIdentification.deviceId
  const {
    isLoading,
    initialValues,
    merchantIdentificationStatuses,
    handleSubmitUpdateMerchantIdentificationStatuses,
  } = useUpdateIdentification(selectedMerchantIdentification, onCloseModal)

  const identStatusOptions: DropDownItem[] = useMemo(() => {
    return merchantIdentificationStatuses.map((item) => ({
      label: String(item.statusName),
      value: String(item.statusId),
    }))
  }, [merchantIdentificationStatuses])

  if (isLoading) return <></>

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UpdateMerchantIdentificationStatusesSchemas}
      onSubmit={handleSubmitUpdateMerchantIdentificationStatuses}
    >
      {({ values, isSubmitting, setFieldValue, errors, touched }) => {
        return (
          <Form className="flex flex-col">
            <div className="mb-4">
              <Dropdown
                title="Status"
                options={identStatusOptions}
                className="w-full mb-5 menu-outer-top"
                borderColor="#C4C4C4"
                onSelect={(value) => {
                  setFieldValue('identStatusId', parseInt(value.value))
                }}
                overflowStyle
              />
            </div>

            <footer className="flex justify-end pb-4 pl-4 pr-4">
              <Button
                type="submit"
                className="mr-2 rounded bg-primary"
                disabled={isSubmitting}
              >
                <span className="tracking-wider font-bold">Submit</span>
              </Button>
              <Button
                className="bg-white border rounded border-primary"
                onClick={onCloseModal}
                style={{ color: Colors.primary }}
              >
                <span className="tracking-wider font-bold">Cancel</span>
              </Button>
            </footer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Index
