import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

// AUTH TOKEN START
import jwt_decode from 'jwt-decode'
import { ls, formatter } from 'utils'
import { LSKEY_TOKEN } from 'constants/app'
// AUTH TOKEN END

import {
  MerchantIdentificationTypes,
  UpdateMerchantIndetificationStatusesTypes,
  UpdateMerchantIndetificationStatusesFormTypes,
  UpdateMerchantIndetificationStatusesFormSubmitValuesTypes,
  UpdateMerchantIndetificationStatusesFormSubmitActionsTypes,
  MerchantIdentificationStatusesTypes,
} from 'types/merchant'

import useMerchantStore from 'store/merchant'

const UPDATEMERCHANTIDENTIFICATIONSTATUSINITVAL: UpdateMerchantIndetificationStatusesTypes =
  {
    ident: '',
    identTypeId: 0,
    identStatusId: 0,
    identId: 0,
  }

export const useUpdateIdentification = (
  selectedMerchantIndentification: MerchantIdentificationTypes,
  onCloseModal: () => void
): {
  isLoading: boolean
  initialValues: UpdateMerchantIndetificationStatusesTypes
  merchantIdentificationStatuses: MerchantIdentificationStatusesTypes[]
  handleSubmitUpdateMerchantIdentificationStatuses: (
    values: UpdateMerchantIndetificationStatusesFormSubmitValuesTypes,
    actions: UpdateMerchantIndetificationStatusesFormSubmitActionsTypes
  ) => void
  // handleClearPurchaseTx: () => void
} => {
  const { traderId } = useParams()
  const merchantTraderId = Number(traderId) || 0
  // AUTH TOKEN START
  const authToken = ls.get(LSKEY_TOKEN)
  const decodeAuthToken: { username: string } = jwt_decode(String(authToken))
  const authTokenUsername = decodeAuthToken?.username || ''
  // AUTH TOKEN END
  // MERCHANT CREATE IDENT START
  const updateMerchantIdentificationStatuses = useMerchantStore(
    (state) => state.updateMerchantIdentificationStatuses
  )
  const merchantDeviceUpdateLoading = useMerchantStore(
    (state) => state.merchantDeviceUpdateLoading
  )
  const merchantIdentificationStatuses = useMerchantStore(
    (state) => state.merchantIdentificationStatuses
  )
  const merchantIdentificationStatusesLoading = useMerchantStore(
    (state) => state.merchantIdentificationStatusesLoading
  )
  const getMerchantIdentificationStatuses = useMerchantStore(
    (state) => state.getMerchantIdentificationStatuses
  )
  // MERCHANT CREATE IDENT END
  // MISC STATES START
  const [initialValues, initialValuesSet] =
    useState<UpdateMerchantIndetificationStatusesFormTypes>({
      ...UPDATEMERCHANTIDENTIFICATIONSTATUSINITVAL,
      ident: selectedMerchantIndentification.ident,
      identTypeId: selectedMerchantIndentification.identTypeId,
      identId: selectedMerchantIndentification.identId,
    })
  // MISC STATES END

  const isLoading = useMemo(() => {
    return merchantDeviceUpdateLoading && merchantIdentificationStatusesLoading
      ? true
      : false
  }, [merchantDeviceUpdateLoading, merchantIdentificationStatusesLoading])

  const handleSubmitUpdateMerchantIdentificationStatuses = useCallback(
    async (
      values: UpdateMerchantIndetificationStatusesFormSubmitValuesTypes,
      actions: UpdateMerchantIndetificationStatusesFormSubmitActionsTypes
    ) => {
      try {
        await updateMerchantIdentificationStatuses(merchantTraderId, values)
        toast.success('Merchant Identification status update successful.')
        onCloseModal()
        // actions.setSubmitting(false)
      } catch (error) {
        const message = JSON.parse(JSON.stringify(error))
        if (message.outcomeCode === 'HDA:UPD_IDENT_STATUS:8') {
          toast.error(
            `Changing Cancelled Merchant Identification status is not allowed.`
          )
        } else {
          toast.error(`Merchant Identification status update error.`)
        }
      }
    },
    [updateMerchantIdentificationStatuses]
  )

  const handleClearPurchaseTx = useCallback(() => {
    initialValuesSet({ ...UPDATEMERCHANTIDENTIFICATIONSTATUSINITVAL })
    toast.success('Filter successfully cleared.')
  }, [initialValuesSet])

  useEffect(() => {
    getMerchantIdentificationStatuses()
  }, [])

  return {
    isLoading,
    initialValues,
    merchantIdentificationStatuses,
    handleSubmitUpdateMerchantIdentificationStatuses,
    // handleClearPurchaseTx,
  }
}
